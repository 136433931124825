import React from "react"
import styles from "./about-css-modules.module.styl"
import Header from "../components/header"
import Layout from "../components/layout"

const User = props => (
  <div className={styles.user}>
    <img src={props.avatar} className={styles.avatar} alt={""} />
    <div className={styles.description}>
      <h2 className={styles.username}>{props.username}</h2>
      <p className={styles.excerpt}>{props.excerpt}</p>
    </div>
  </div>
)

export default () => (
  <Layout current={"about-css-modules"}>
    <Header headerText={"About CSS Modules"} />
    <p>CSS Modules are neat!</p>
    <User
      username="Jane Doe"
      avatar="https://via.placeholder.com/96.webp"
      excerpt="I'm Jane Doe. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
    />
    <User
      username="Bob Smith"
      avatar="https://via.placeholder.com/96.webp"
      excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
    />
  </Layout>
)
